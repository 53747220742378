import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link as RouterLink } from 'react-router-dom';
import ExistingClubProject from "./ExistingClubProject";

// Function to extract initials
const getInitials = (name) => {
    const words = name.split(' ');
    const initials = words.map((word) => word[0]).join('').toUpperCase();
    return initials.length > 2 ? initials.slice(0, 2) : initials;
};

export default function ProjectHeader({ projectData, userTeamName, handleEnrollment, isClubMember, getExistingTeamNames, loading }) {
    const [imgError, setImgError] = useState(false);
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: "center", marginBottom: 2 }}>
            {imgError ? (
                <Box sx={fallbackStyle}>{getInitials(projectData?.title)}</Box>
            ) : (
                <img
                    src={projectData?.image}
                    alt={`${projectData?.title} logo`}
                    style={{ maxWidth: "150px", height: "150px", borderRadius: "10px" }}
                    onError={() => setImgError(true)}
                />
            )}
            <Typography variant="h4" mt={2}>
                {projectData?.title}
            </Typography>

            <Typography variant="h6" color="textSecondary" mb={1}>
                {projectData?.description}
            </Typography>
            <Typography mb={1}>
                {projectData?.project_type} Project
            </Typography>
            {loading ? 'Loading... ':

            <Box>
            {userTeamName ? (
                <Typography variant="body1" paragraph >
                    You are member of team <strong>{userTeamName}</strong>
                </Typography>
            ) : (
                isClubMember ?
                    //  <Button variant="contained" color="primary" onClick={handleEnrollment}>
                    //     Enroll
                    // </Button>
                    <ExistingClubProject projectType={projectData?.project_type} getExistingTeamNames={getExistingTeamNames} handleCreateTeam={handleEnrollment} />
                    : <Button
                        component={RouterLink}
                        to='/create-club'
                        variant="contained"
                    // sx={{ width: '5.625rem' }}
                    >
                        Create your club and participate
                    </Button>
            )}
            </Box>}
        </Box>
    );
}

const fallbackStyle = {
    width: '150px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center',
    backgroundColor: '#f0f0f0', color: '#333', fontSize: '36px', fontWeight: 'bold',
    borderRadius: '10px', border: '3px solid #f0f0f0', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
};
