import axios from 'axios';
import { getAuth } from "firebase/auth";
import AmplitudeEvent from '../components/Amplitude/AmplitudeEvent'
import firebaseErrors from "../firebaseErrors.json";
import 'firebase/auth';
import { Timestamp} from "firebase/firestore";


const baseURL = "https://www.roundpier.com/api";


// export function getCurrentUser() {
//   const currentUser = getAuth().currentUser;
//   return currentUser;
// }

export function getCurrentUser() {
  const profile = JSON.parse(localStorage.getItem('profile'));
  return profile?.response?.user
}


export function getUserId() {
  const userId = getAuth()?.currentUser?.uid;
  return userId;
}

export function get(endpoint) {
  return axios.get(baseURL + endpoint)
    .then(response => response.data)
    .catch(error => console.error(error));
}

export function post(endpoint, reqData) {
  return axios.post(baseURL + endpoint, reqData)
    .then(response => response.data)
    .catch(error => console.error(error));
}


export function Amplitude() {
  return AmplitudeEvent();
}

export function getErrorMessage(error) {
  const code = error.code;
  const message = firebaseErrors[code] || "An unknown error occurred.";
  return message;
}

export const formatTimestamptoDate = (date) => {  
  if (!date) return null 
  else {
      const newDate = date instanceof Timestamp ? date.toDate() : date
     return new Date(newDate).toLocaleDateString("en-GB")
  }
}

export const formatTimestamptoDateTime = (date) => {  
  if (!date) return null 
  else {
      const newDate = date instanceof Timestamp ? date.toDate() : date
     return new Date(newDate).toLocaleString("en-GB",{year:"numeric",month:"numeric",day:"numeric",hour: '2-digit', minute:'2-digit'})
  }
}