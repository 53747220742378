import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Function to extract initials
const getInitials = (name) => {
    const words = name.split(' ');
    const initials = words.map((word) => word[0]).join('').toUpperCase();
    return initials.length > 2 ? initials.slice(0, 2) : initials;
};

export default function ClubHeader({ clubData, currentUser, isMember, handleJoinClub }) {
    const [imgError, setImgError] = useState(false);
    return (
        <>
            {/* Club Image */}
            <Box sx={{ display: "flex", flexDirection:"column", alignItems:'center', justifyContent: "center", marginBottom: 2 }}>
                {imgError ? (
                    <Box sx={fallbackStyle}>{getInitials(clubData.name)}</Box>
                ) : (
                    <img
                        src={clubData.url}
                        alt={`${clubData.name} logo`}
                        style={{ maxWidth: "150px", height: "150px", borderRadius: "10px" }}
                        onError={() => setImgError(true)}
                    />
                )}
           

            {/* Club Name */}
            <Typography variant="h4" component="h3" mt={2}>
                {clubData.name}
            </Typography>

            {/* Club Category */}
            <Typography variant="h5" component="h2" color="textSecondary" mb={2}>
                {clubData.category}
            </Typography>
            {/* Join/Admin/Membership Buttons */}
                {currentUser && clubData.admin === currentUser.id ? (
                    <Typography variant="body1" paragraph >
                        You are the admin of this club
                    </Typography>
                ) : isMember ? (
                    <Typography variant="body1" paragraph >
                        You are a member of this club
                    </Typography>
                ) : (
                    <Button variant="contained" color="primary" onClick={handleJoinClub}>
                        Join Club
                    </Button>
                )}
            </Box>
        </>
    );
}

const fallbackStyle = {
    width: '150px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center',
    backgroundColor: '#f0f0f0', color: '#333', fontSize: '36px', fontWeight: 'bold',
    borderRadius: '10px', border: '3px solid #f0f0f0', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)',
};
