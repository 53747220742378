import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { Grid, Card, CardMedia, CardContent } from "@mui/material";
import { Link } from "react-router-dom";

export const ExploreTopics = (props) => {

  return (
    <>
      <Typography variant="h6" marginBottom={3}>
        Explore RoundPier Topics
      </Typography>

      <Grid id="topics-list" container spacing={2}>
        {props.data?.map((topic, index) => (
          <Grid
            item
            key={index}
            sx={{
              display: "flex",
              flexBasis: "200px",
              justifyContent: { xs: "center", md: "flex-start" }, // center on xs, left on sm and larger
            }}
          >
            <Card sx={{ p: 2, width: 200, height: 200 }}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/club-topics/${topic.category}`}
              >
                <Box sx={{ display: "flex", justifyContent: "center"  }}>
                  <Avatar
                    src={topic.url}
                    alt={topic.name}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", textAlign: "center", pt: "16px", height: 88 }}>
                  <Typography variant="h6" color="text.secondary">
                    {topic.name}
                  </Typography>
                  {/* <Typography variant="body" color="text.secondary">
                    {topic.category}
                  </Typography> */}
                </Box>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
