import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Container, Button, Grid, } from '@mui/material';
import { StyledButton } from "../../commonComponents/StyledComponents";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export const ClubDetails = (props) => {

  const DetailCards = (<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 3, lg: 4 }} justifyContent='space-between'>
    {props.data?.map((item) => (
      <Grid item key={item.title} xs={12} sm={4}>
        <ImageListItem cols={2}>
          <img
            srcSet={`${item.image}`}
            src={`${item.img}`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            sx={{ textAlign: 'center' }}
          />
        </ImageListItem>
      </Grid>
    ))}
  </Grid>)

  const DetailsDescription = (
    <Grid paddingTop={6} container>
      <Grid item xs={12} lg={4} alignSelf='center' sx={{textAlign:{lg:'center'}}}>
        <Typography variant="h6">How Clubs Works</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box sx={{ py: 2, px: {lg:3}, borderLeft: { xs: 'unset', lg: '3px solid #EF5350' } }}>
          <Typography variant="p">You can create a group, club or chapter and link it to your school or organization you are part of. It could be a general group for students in your school or specific club for your members.</Typography>
        </Box>
      </Grid>
    </Grid>)



  return (
    // <Box sx={{ mt: 3, mb: 3 }}>
    <Box sx={{ mb: 3 }}>
      {DetailCards}
      {DetailsDescription}
    </Box>
  );
}
