import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, Button, Card, CardContent, MenuItem, Typography } from '@mui/material';
import { Link, Link as RouterLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@emotion/react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';


export default function SubmitWork({ clubId, projectId, team, user, submission,fetchData }) {
  const [submitDialog, setSubmitDialog] = useState(false)
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const submitValuesRef = useRef({
    submitURL: '',
    message: ''
  })
  const handleClickOpen = () => {
    setSubmitDialog(true);
    submitValuesRef.current.submitURL = ''
  };

  const handleClose = () => {
    setSubmitDialog(false);
  };

  const handleChange = (e) => {
    submitValuesRef.current[e.target.name] = e.target.value;
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!submitValuesRef.current.submitURL) newErrors.submitURL = "Link is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        if (!(clubId && projectId && team?.name && user)) throw new Error("club Id/project id/team name/user missing");
        else {
          const customId = `${clubId}_${projectId}_${team.name}_${user}`; // Example custom ID, you can use any unique identifier
          const submissionData = {
            club_id: clubId,
            project_id: projectId,
            team: team?.name,
            user: user,
            submission_link: submitValuesRef.current.submitURL,
            message: submitValuesRef.current.message
          }
          await setDoc(doc(db, "submissions", customId), {
            submissionData
          });
          setLoading(false)
        }
        submitValuesRef.current = {
          submitURL: '',
          message: ''
        }
        setSubmitDialog(false)
        fetchData()
      } catch (error) {
        setLoading(false)
        console.error("Error in submission:", error);
      }

    }
  };

  const CreateTeamDialog = (<Dialog
    disableEscapeKeyDown
    maxWidth='sm'
    open={submitDialog}
    onClose={(handleClose)}
    PaperProps={{
      component: 'form',
      onSubmit: handleSubmit,
    }}
  >
    <DialogTitle>Submit your work</DialogTitle>
    <DialogContent sx={{ minWidth: '600px' }}>
      <TextField
        fullWidth
        label="Submission Link"
        name="submitURL"
        id="submitURL"
        margin="normal"
        defaultValue={submitValuesRef.current.submitURL}
        onChange={handleChange}
        error={!!errors.submitURL}
        helperText={errors.submitURL || 'Provide the link to your submission'}
      />
      <TextField
        fullWidth
        label="Message"
        name="message"
        id="message"
        margin="normal"
        defaultValue={submitValuesRef.current.message}
        onChange={handleChange}
      />
    </DialogContent>
    <DialogActions sx={{ p: 3 }}>
      <Button variant='text' onClick={handleClose}>Cancel</Button>
      <Button variant='contained' type="submit" disabled={loading}>Submit</Button>
    </DialogActions>
  </Dialog>)

  const SubmittedData = <>
    <Box display='flex'>
      <Typography fontWeight="bold" textAlign="start" gutterBottom>
        Link:
      </Typography>
      <Typography variant="p" textAlign="start" ml={1}>
        <Link target="_blank" underline="none" to={submission?.submission_link}>{submission?.submission_link}</Link>
      </Typography>
    </Box>
    <Box display='flex' flexGrow={1}>
      <Typography fontWeight="bold" textAlign="start">
        Message:
      </Typography>
      <Typography variant="p" textAlign="start" ml={1}>
        {submission?.message}
      </Typography>
    </Box>
    <Button
      sx={{ mt: 2 }}
      variant="contained"
      onClick={handleClickOpen}
    >
      Re-submit your work
    </Button>
  </>

  return (

    <Box>
      {CreateTeamDialog}
      <Card sx={{ my: 4 }} variant="outlined">
        <CardContent>
          <Typography variant='h6' fontWeight="bold" textAlign="start" mb={2}>
            Your Submission
          </Typography>
          {loading && 'Loading...'}
          {!loading && !submission && <Typography mb={2}>You do not have any submissions!</Typography>}
          {!loading && submission ? SubmittedData : !loading && <Button
            variant="contained"
            onClick={handleClickOpen}
          >
            Submit your work
          </Button>}
        </CardContent>
      </Card>
    </Box>

  );
}
