import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Button, DialogContent } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
// import MemberListDialog from './MemberListDialog';
import InviteDialog from "./InviteDialog";

function createData(memberId, name, school, country, email, inviteAccepted) {
  return {
    memberId,
    name,
    school,
    country,
    email,
    inviteAccepted,
  };
}

const members = [
  createData(
    1,
    "Alice Johnson",
    "Greenwood High School",
    "USA",
    "alice.johnson@example.com",
    true
  ),
  createData(
    2,
    "Bob Smith",
    "Blue Valley High",
    "Canada",
    "bob.smith@example.com",
    false
  ),
  createData(
    3,
    "Carlos Garcia",
    "Saint Mary School",
    "Mexico",
    "carlos.garcia@example.com",
    true
  ),
  createData(
    4,
    "David Lee",
    "Sunshine School",
    "Australia",
    "david.lee@example.com",
    false
  ),
  createData(
    5,
    "Eva Zhang",
    "Happy Valley School",
    "China",
    "eva.zhang@example.com",
    true
  ),
  createData(
    6,
    "Fatima Khan",
    "Springfield School",
    "UAE",
    "fatima.khan@example.com",
    false
  ),
  createData(
    7,
    "George Brown",
    "Oakwood High",
    "UK",
    "george.brown@example.com",
    true
  ),
  createData(
    8,
    "Hannah Martin",
    "Elm Street School",
    "Germany",
    "hannah.martin@example.com",
    false
  ),
  createData(
    9,
    "Isabella Rossi",
    "Florence Academy",
    "Italy",
    "isabella.rossi@example.com",
    true
  ),
  createData(
    10,
    "Jack Wilson",
    "Pine Grove High",
    "New Zealand",
    "jack.wilson@example.com",
    false
  ),
  createData(
    11,
    "Kaito Tanaka",
    "Tokyo International",
    "Japan",
    "kaito.tanaka@example.com",
    true
  ),
  createData(
    12,
    "Liam Murphy",
    "Emerald Secondary",
    "Ireland",
    "liam.murphy@example.com",
    true
  ),
  createData(
    13,
    "Maria Lopez",
    "Valley View School",
    "Spain",
    "maria.lopez@example.com",
    false
  ),
  createData(
    14,
    "Nina Petrov",
    "Riverbank School",
    "Russia",
    "nina.petrov@example.com",
    true
  ),
  createData(
    15,
    "Oliver Evans",
    "Westwood School",
    "South Africa",
    "oliver.evans@example.com",
    false
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  // {
  //   id: 'memberId',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Member ID',
  // },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  // {
  //   id: 'school',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'School',
  // },
  // {
  //   id: 'country',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Country',
  // },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  // {
  //   id: 'inviteAccepted',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Invite Accepted',
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name?.split(' ')?.[0]?.[0]}${name?.split(' ')?.[1]?.[0]}`,
  };
}

export default function EnhancedTable({ members }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openInviteDialog, setOpenInviteDialog] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = members.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty members.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...members]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage]
  );

  return (
    <Paper sx={{ mb: 2, maxWidth: "100%" }}>
      <Box sx={{ my: 2, display: "flex" }}>
        <Button
          sx={{ ml: "auto" }}
          variant="contained"
          startIcon={<PeopleIcon />}
          onClick={() => setOpenInviteDialog(true)}
        >
          Invite members
        </Button>
      </Box>
      {openInviteDialog && (
        <InviteDialog
          open={openInviteDialog}
          handleClose={() => setOpenInviteDialog(false)}
        />
      )}
      <TableContainer sx={{ maxHeight: 440, maxWidth: "88vw" }}>
        <Table stickyHeader sx={{ minWidth: 600 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={members.length}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      width: "100%",
                    }}
                  >
                    <Avatar
                      src={row.icon}
                      {...stringAvatar(row.name)}
                    />
                    
                    {row.name}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={members.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
