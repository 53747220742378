import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./commonComponents/Loading";
import { Box } from "@mui/material";

const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(`${name}=`));
    return cookie ? cookie.split("=")[1] : null;
  };
  
  useEffect(() => {
    setLoading(true);
    const token = getCookie("token");

    // Extract subdomain
    const host = window.location.host; // e.g., subdomain.domain.com
    const subdomain = host.split(".")[0]; // Extracts 'subdomain'

    if (token && subdomain) {
      // Perform subdomain validation (if required)
      const validSubdomains = ["clubs"]; // Add allowed subdomains

      if (process.env.NODE_ENV !== "development" && !validSubdomains.includes(subdomain)) {
        console.error("Invalid subdomain!");
        sessionStorage.removeItem("token");
        setLoading(false);
        navigate("/");
      } else {

        localStorage.setItem('token', token);
        fetch(`https://www.roundpier.com/api/user?token=${token}`)
          .then(profileResponse => {
            if (!profileResponse.ok) {
              throw new Error('Failed to fetch user profile');
            }
            return profileResponse.json();
          })
          .then(profileData => {
            // Store the profile in localStorage
            localStorage.setItem('profile', JSON.stringify(profileData));
            navigate('/clubs')
            setLoading(false);

          })
          .catch(error => {
            setLoading(false);
            console.log("Error while auto login...", error);
          })
      }
    } else {
      // Redirect to login if no token
      setLoading(false);

      navigate("/");
    }
  }, [navigate]);

  if (loading) return <Box width="100vw" height="100vh">
    <Loading />
  </Box>

  // Render children if authorized
  return <>{children}</>;
};

export default AuthWrapper;
