import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Paper, Divider } from '@mui/material';
import { getFirestore, doc, setDoc, arrayUnion } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import topicsData from "../data/topicsData.json";
import ExistingClubProject from '../components/project/ExistingClubProject';
import NewClubProject from '../components/project/NewClubProject';
import { EnhancedTable } from '../components/memberClubDetails/MemberListDialog';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FileThumbnail from '../components/commonComponents/FileThumbnail'
import { checkMembership, fetchClubProjectById, fetchEnrollments, fetchSubmissionByUser } from '../services/apiService';
import ProjectHeader from '../components/project/ProjectHeader';
import Loading from '../components/commonComponents/Loading'
import SubmitWork from '../components/project/SubmitWork';
import OtherTeamSubmissions from '../components/project/OtherTeamSubmissions';
import axios from 'axios';
import CollapsibleTable from '../components/CollapsibleTable';

export default function Project() {

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search)
  // const role = queryParams.get("role");
  const { clubId, projectId } = useParams()
  const [openDialog, setOpenDialog] = useState(false)
  const [userTeam, setUserTeam] = React.useState(false)
  const [project, setProject] = useState();
  const [isClubMember, setIsClubMember] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enrollments, setEnrollments] = useState([]);
  const [currentEnrollments, setCurrentEnrollments] = useState([])
  const [submission, setSubmission] = useState(null);


  const projectBackground = document.getElementById("project-background")
  const taskDescription = document.getElementById("task-description")

  if (projectBackground) {
    projectBackground.innerHTML = project?.project_background ?? ''
  }
  if (taskDescription) {
    taskDescription.innerHTML = project?.task_description ?? ''
  }

  const profile = JSON.parse(localStorage.getItem('profile'));
  const currentUser = profile.response.user;

  useEffect(() => {
    fetchData();
  }, [currentUser?.id, clubId]);

  async function fetchData() {
    const enrollmentsList = await fetchEnrollments()
    setEnrollments(enrollmentsList)
    const projectData = await fetchClubProjectById(projectId)
    setProject(projectData)
    // setEnrollments(enrollmentsList);
    // const projectList = []
    if (clubId) {
      const enrollment = enrollmentsList.find(enrollment => (enrollment.projectId === projectId && enrollment.clubId === clubId));
      
      try {
        const updatedTeams = await Promise.all(
          enrollment.teams.map(async (team) => {
            // Fetch data for all members in the team
            const responses = await Promise.all(
              team.members.map((memberId) =>
                axios.get(`https://www.roundpier.com/api/user/view/${memberId}?token=98954_5d71df19d1bf7cc64951c5d22822f65a`)
              )
            );
            const mappedData = responses.map((response) => {
              let userData = response.data.response.user;
              return {
                name: userData.name,
                type: userData.type,
                icon: userData.meta.og_image,
                otherData: userData,
              };
            });
  
            // Return the team object with members replaced by their data
            return {
              ...team,
              members: mappedData,
            };
          })
        );
  
        setCurrentEnrollments({...enrollment, teams: updatedTeams})
      } catch (err) {
        console.error(err);
      } finally {
      }
      const team = enrollment ? enrollment?.teams?.find(team => team?.members?.includes(currentUser?.id)) : null
      const membershipsCollection = collection(db, "memberships");
      const membershipsQuery = query(membershipsCollection, where("user", "==", currentUser.id), where("clubId", "==", clubId));
      const membershipsSnapshot = await getDocs(membershipsQuery);
      setIsClubMember(!membershipsSnapshot.empty);
      
      setUserTeam(team)
      if (team?.name && !membershipsSnapshot.empty) {
        const submissionData = await fetchSubmissionByUser(clubId, projectId, team?.name, currentUser?.id)
        setSubmission(submissionData?.submissionData)
      }

    }
    setLoading(false)
    // setModifiedProjectList(projectList)
  }

  const handleEnrollment = async (teamData) => {
    const docName = `${clubId}_${projectId}`
    const docRef = doc(db, "enrollments", docName);
    try {
      // Update the field or create the document if it doesn't exist
      teamData.members = [...(teamData.members ?? []), currentUser.id]
      await setDoc(
        docRef,

        { clubId,projectId,teams: arrayUnion(teamData) },
        { merge: true }
      );

      console.log("Enrollment updated/created successfully.");
      fetchData()
    } catch (error) {
      if (error.code === "not-found") {
        // Document does not exist, create it with the array field
        await setDoc(docRef, {
          teams: teamData, // Create the field as a new array
        });
        console.log("Document created new team");
        fetchData()
      } else {
        console.error("Erroradding new team:", error);
      }
    }

  }

  const getExistingTeamNames = () => {
    const docName = `${clubId}_${projectId}`
    const currentDocObj = enrollments.find(enrollment => enrollment.id === docName)
    const teamNames = currentDocObj?.teams.map(team => team.name)
    return teamNames ?? []
  }

  const MemberListDialog = ({ teams }) => {
    
    const handleClose = () => {
      setOpenDialog(false);
    };
    return <Dialog onClose={handleClose} open={openDialog} maxWidth='md' fullWidth >
      <DialogTitle>Team members</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <CollapsibleTable teamMembers={teams}/>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant='outlined' onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  }

  return (
    <Box sx={{ py: 6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
      <Paper sx={{ p: 6, pt: 3 }}>
        {userTeam?.members?.length &&
          <Box display='flex'>
            <Button sx={{ mb: 3, ml: 'auto' }} onClick={() => setOpenDialog(true)} variant="text">Show Members</Button>
            {openDialog && (
              <MemberListDialog 
                teams={currentEnrollments && currentEnrollments?.teams?.length > 0 ? currentEnrollments.teams : []} 
              />
            )}
          </Box>
        }
        <ProjectHeader projectData={project} userTeamName={userTeam?.name} handleEnrollment={handleEnrollment} isClubMember={isClubMember} getExistingTeamNames={getExistingTeamNames} loading={loading} />
        <Divider />
        <Typography mt={2} variant='h6' fontWeight={600}>
          Project Background
        </Typography>
        <Box my={2}>
          {project?.video_link && <iframe width="500" height="340"
            src={project?.video_link}>
          </iframe>}
        </Box>
        <div id="project-background"></div>
        <Typography variant='h6' gutterBottom fontWeight={600}>
          Your Task
        </Typography>
        <div id="task-description"></div>
        <Typography variant='h6' gutterBottom my={2} fontWeight={600}>
          Resources
        </Typography>
        <Box mb={6}>
          <FileThumbnail file={project?.resource}
            id={1}
            buttonId={2}
            loading={false}
            S3Download={() => { }}
          />
        </Box>
        {userTeam?.members?.length && <SubmitWork mb={6} clubId={clubId} projectId={projectId} team={userTeam} user={currentUser?.id ?? ''} submission={submission} fetchData={fetchData}/>}
        <Typography variant='h6' gutterBottom my={2} fontWeight={600}>
          Teams which already submitted
        </Typography>
        <OtherTeamSubmissions teams={currentEnrollments && currentEnrollments?.teams?.length > 0 ? currentEnrollments.teams : []} />
      </Paper>
    </Box>

  );
}
