import React, { useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { Grid, Card, CardMedia, CardContent } from "@mui/material";
import { Link } from "react-router-dom";

// Style for the club box
const clubBoxStyle = {
  padding: 2,
  textAlign: "center",
  borderRadius: "8px",
  border: "1px solid #ddd",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
  },
};

// Style for the image
const imgStyle = {
  maxWidth: "150px",
  height: "150px",
  objectFit: "cover",
  cursor: "pointer",
  borderRadius: "50%",
  border: "3px solid #f0f0f0",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
};

// Style for the fallback initials box
const fallbackStyle = {
  width: "150px",
  height: "150px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f0f0f0",
  color: "#333",
  fontSize: "36px",
  fontWeight: "bold",
  borderRadius: "50%",
  border: "3px solid #f0f0f0",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.15)",
};

// Component to handle image fallback to initials
const ClubImage = ({ club, getInitials }) => {
  const [imgError, setImgError] = useState(false); // State to check if image loading failed

  return (
    <>
      {imgError ? (
        // If image fails to load, show initials
        <Box sx={fallbackStyle}>{getInitials(club.name)}</Box>
      ) : (
        // Otherwise show the image
        <img
          src={club.url}
          alt={club.name}
          style={imgStyle}
          onError={() => setImgError(true)} // Set image error state on load failure
        />
      )}
    </>
  );
};

export default function ClubList({ clubs, title }) {
  // Function to extract initials
  const getInitials = (name) => {
    const words = name.split(" ");
    const initials = words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    return initials.length > 2 ? initials.slice(0, 2) : initials;
  };

  // return (
  //   <>
  //     <h6>{title}</h6>
  //     <Grid container spacing={2}>
  //       {clubs.map(club => (
  //         <Grid item xs={12} sm={6} md={2} key={club.id}>
  //           <Box sx={{ ...clubBoxStyle }}>
  //             <a href={`club?id=${club.id}`} rel="noopener noreferrer">
  //               <ClubImage club={club} getInitials={getInitials} />
  //             </a>
  //             <Box sx={{ marginTop: 1, fontWeight: 'bold', color: '#333' }}>{club.name}</Box>
  //             <>{club.category}</>

  //           </Box>

  //         </Grid>
  //       ))}
  //     </Grid>
  //   </>
  // );

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" marginBottom={3}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {clubs?.map((club, index) => (
          <Grid
            item
            key={index}
            sx={{
              display: "flex",
              flexBasis: "200px",
              justifyContent: { xs: "center", md: "flex-start" }, // center on xs, left on sm and larger
            }}
          >
            <Card sx={{ p: 2, width: 200, height: 200 }}>
              <Link
                // target="_blank"
                style={{ textDecoration: "none" }}
                to={`/club/${club.id}`}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    src={club.url}
                    alt={club.name}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
                <Box sx={{ textAlign: "center", pt: "16px" }}>
                  <Typography variant="h6" color="text.secondary">
                    {club.name}
                  </Typography>
                  {/* <Typography variant="body" color="text.secondary">
                    {club.category}
                  </Typography> */}
                </Box>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
