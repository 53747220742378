import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ClubList from "./ClubList";
import { ExploreTopics } from "../clubsColdStart/ExploreTopics";
import HeroImage from "../../../assets/images/hero-section.webp";

const categories = [
  "Technology",
  "Finance",
  "Entrepreneurship",
  "Medicine",
  "Creative Writing",
  "Environment",
];

export default function ClubsLanding({ clubsIAmMemberOf, clubsNotAMemberOf }) {
  const navigate = useNavigate();
  return (
    <Box sx={{ py: 6, px: { xs: 2, md: 6, lg: 6, xl: 6 } }}>
      <Box display="flex">
        <Typography variant="h5" mb={6} fontWeight={600}>
          My Clubs
        </Typography>
        <Box ml="auto">
          <Button
            component={RouterLink}
            to={`/create-club`}
            // onClick={()=>enrollToProject()}
            variant="contained"
          >
            Create Club
          </Button>
        </Box>
      </Box>
      {clubsIAmMemberOf?.length > 0 && (
        <ClubList clubs={clubsIAmMemberOf} title="Clubs I'm a Member Of" />
      )}
      {clubsNotAMemberOf?.length > 0 && (
        <ExploreTopics data={clubsNotAMemberOf} />
      )}
    </Box>
  );
}
