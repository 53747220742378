import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { userId, get, post, getCurrentUser } from "../Helper";
import { db } from "../../firebase";
import Members from "./MemberList";
import ProjectList from "./ProjectList";
import Updates from "./Updates";
import projectListData from "../../data/projectListData.json";
import { fetchClubProjects, fetchEnrollments, fetchProjectsByCategory, fetchUpdatesByClub } from "../../services/apiService";

function ClubTabs({ members, clubId, clubCategory }) {
    const [name, SetName] = useState([]);
    const [clubProjects, setClubProjects] = useState([]);
    const currentUser = getCurrentUser()
    const [tabValue, setTabValue] = useState(0);
    const [updates, setUpdates] = useState([]);
    const [enrollments, setEnrollments] = useState([]);
    const [loading, setLoading] = useState(true);
    
    
    

    useEffect(() => {
        async function fetchData() {
            const clubProjectList = await fetchProjectsByCategory(clubCategory)
            setClubProjects(clubProjectList);
            const updatesList = await fetchUpdatesByClub(clubId)
            setUpdates(updatesList);
            const enrollmentsList = await fetchEnrollments()
            setEnrollments(enrollmentsList)
            setLoading(false)
        }
        fetchData();
     
    }, [currentUser?.id, clubId]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        get("/user/view/98954?token=98954_2785cbfc7a5a89f7d74958b1befcb7ee")
            .then((data) => {
                SetName(data?.response?.user?.name);
            })
            .catch((error) => console.error(error));
    }, []);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="club tabs">
                    <Tab label="Projects" />
                    <Tab label="Updates" />
                    <Tab label="Members" />
                </Tabs>
            </Box>

            {tabValue === 0 ? loading ? 'Loading...' : (
                <ProjectList projects={clubProjects} enrollments={enrollments}/>
            ):''}

             {tabValue === 1 ? loading ? 'Loading...' : (
                <Updates updates={updates}/>
            ):''}

            {tabValue === 2 ? loading ? 'Loading...' : (
                <Members members={members} />
            ):''}

            

           
        </>
    );
}


export default ClubTabs;
