import React, { useState, useEffect } from "react";
import { ClubBanner } from "./ClubBanner";
import clubsPageData from "../../../data/clubsPageData.json";
import { ClubDetails } from "./ClubDetails";
import { ExploreTopics } from "./ExploreTopics";
import { Box } from "@mui/material";
import Loading from "../../commonComponents/Loading";
import {
  fetchClubs,
  fetchClubsOnlyExplore,
} from "../../../services/apiService";
import { StyledButton } from "../../commonComponents/StyledComponents";
import { Link } from "react-router-dom";

export default function ClubsColdStart() {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const profile = JSON.parse(localStorage.getItem("profile"));
  const currentUser = profile.response.user;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const clubList = await fetchClubsOnlyExplore();
        setClubs(clubList);
      } catch (error) {
        console.error("Error fetching clubs", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser?.id]);

  return (
    <>
      {/* <ClubBanner data={clubsPageData?.banner}/> */}
      <Box sx={{ py: 6, px: { xs: 2, md: 12, lg: 18, xl: 32 } }}>
        <ClubDetails data={clubsPageData?.advantages} />
        <Box width={"100%"} display={"flex"}>
        <StyledButton component={Link} size="large" sx={{width: "200px", margin: "0 auto", height: "50px", fontSize: "20px", mt: 3, mb: 3}} to="/create-club">
          Create Club
        </StyledButton>
        </Box>
        {<ExploreTopics data={clubs} />}
      </Box>
    </>
  );
}
