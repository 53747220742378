import React, { useState, useContext, useEffect } from 'react';
import ClubsLanding from '../components/clubs/clubsLanding/ClubsLanding';
import ClubsColdStart from '../components/clubs/clubsColdStart/ClubsColdStart';
import { collection, getDocs, addDoc, setDoc, doc, query, where } from "firebase/firestore";
import Loading from "../components/commonComponents/Loading";
import { db } from "../firebase"; 
import { fetchClubs, fetchMemberships } from '../services/apiService';



const categories = ["Technology", "Finance", "Entrepreneurship", "Medicine", "Creative Writing", "Environment"];

export default function Clubs() {
  const token = localStorage.getItem('token');

  const profile = JSON.parse(localStorage.getItem('profile'));

  const currentUser = profile.response.user;
  
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userMemberships, setUserMemberships] = useState([]);

  useEffect(() => {
   
    const fetchData = async () => {
      setLoading(true);
      try {
        const clubList = await fetchClubs()
        setClubs(clubList);
        const memberships = await fetchMemberships(currentUser?.id)  
        setUserMemberships(memberships);
      } catch (error) {
        console.error("Error fetching clubs or memberships:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser?.id]);

  
  const clubsIAmMemberOf = clubs.filter(club => userMemberships?.some(membership => membership.clubId === club.id));
  // const clubsNotAMemberOf = clubs.filter(club => !userMemberships?.some(membership => membership.clubId === club.id) && club?.explore === true);
  const clubsNotAMemberOf = clubs.filter(
    club => !userMemberships?.some(membership => membership.clubId === club.id) && club?.explore === true
  );

  if(loading) return <Loading />

  else if(currentUser && clubsIAmMemberOf?.length>0)  return <ClubsLanding clubsIAmMemberOf={clubsIAmMemberOf} clubsNotAMemberOf={clubsNotAMemberOf}/>
  
  else return <ClubsColdStart/>
  


}
