import React from "react";
import { Box, Card, CardContent, Typography, Avatar, AvatarGroup, Tooltip } from "@mui/material";

function getRandomColor() {
  const colors = ["#FF5733", "#33FF57", "#3357FF", "#F4D03F", "#BB8FCE", "#5DADE2", "#48C9B0", "#F1948A"];
  return colors[Math.floor(Math.random() * colors.length)];
}

export default function OtherTeamSubmissions({
  teams
}) {
  // const teams = [
  //   {
  //     name: "Team Gladiator",
  //     members: [
  //       { name: "Alice Johnson", user: 223, email: "alice.johnson@example.com" },
  //       { name: "Bob Smith", user: 2, email: "bob.smith@example.com" },
  //       { name: "Carlos Garcia", user: 3, email: "carlos.garcia@example.com" },
  //       { name: "David Lee", user: 4, email: "david.lee@example.com" },
  //       { name: "Eva Zhang", user: 5, email: "eva.zhang@example.com" },
  //     ],
  //   },
  //   {
  //     name: "Team Warriors",
  //     members: [
  //       { name: "Fatima Khan", user: 6, email: "fatima.khan@example.com" },
  //       { name: "George Brown", user: 7, email: "george.brown@example.com" },
  //       { name: "Hannah Martin", user: 8, email: "hannah.martin@example.com" },
  //     ],
  //   },
  // ];
  
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {teams.map((team, index) => (
        <Card key={index} variant="outlined">
          <CardContent>
            <Box display="flex"  alignItems="center" gap={2}>
              {/* Team Name */}
              <Typography variant="h6" fontWeight="bold" textAlign="center">
                {team.name}
              </Typography>

              {/* Members as Avatars */}
              <AvatarGroup max={4} sx={{mx:'auto'}}>
                {team.members.map((member, idx) => (
                  <Tooltip key={idx} title={member?.name ? member?.name : member?.otherData.id}>
                    <Avatar sx={{ bgcolor: getRandomColor() }}>
                      {member?.name ? member?.name?.charAt(0) : member?.otherData?.id.charAt(0)}
                    </Avatar>
                  </Tooltip>
                ))}
                {team.members.length > 2 && (
                  <Avatar sx={{ bgcolor: "#d3d3d3" }}>+{team.members.length - 2}</Avatar>
                )}
              </AvatarGroup>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
