import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ContentCopy as CopyIcon } from '@mui/icons-material';

export default function InviteDialog({ open, handleClose }) {
  const currentLink = window.location.href; // Get the current page link
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(currentLink);
      setSnackbarOpen(true); // Open the snackbar on success
    } catch (err) {
      console.error('Failed to copy the link:', err);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            minWidth: 500,
          },
        }}
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        <DialogTitle>Copy the URL to share</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            value={currentLink}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Tooltip title="Copy URL">
                <IconButton onClick={handleCopy}>
                  <CopyIcon />
                </IconButton>
                </Tooltip>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} sx={{ width: '100%' }}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
}
