import axios from 'axios';
import { db } from '../firebase';
import { collection, getDocs, doc, query, where, getDoc } from "firebase/firestore";

const CLUB_PROJECTS = 'club-projects'
const CLUBS = 'clubs'
const MEMBERSHIPS = "memberships"
const ENROLLMENTS = "enrollments"
const UPDATES = "updates"
const SUBMISSIONS = "submissions"

export const fetchClubProjects = async (clubId) => {
    try {
        const clubSnapshot = await getDocs(collection(db, CLUB_PROJECTS));
        const clubProjectList = clubSnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(doc => doc.club === clubId); // Filter by clubId

        return clubProjectList
    } catch (error) {
        console.error("Error fetching clubs or memberships:", error);
    } finally {
        console.log(true);
    }
};

export const fetchProjectsByCategory = async (category) => {
  try {
    const q = query(collection(db, CLUB_PROJECTS), where("category", "==", category));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const projectList = querySnapshot?.docs?.map(doc => ({ id: doc.id, ...doc.data() }))
      return projectList
    } else {
      console.error("No document found for the provided category.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching project by category", error);
  }
};

export const fetchClubProjectById = async (projectId) => {
  try {
    const docRef = doc(db, CLUB_PROJECTS, projectId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      console.error("No document found for the provided projectId.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching project by id", error);
  }
};

export const fetchClubsOnlyExplore = async () => {
    try {
      const q = query(collection(db, CLUBS), where("explore", "==", true));
      const clubSnapshot = await getDocs(q);
      
      const clubList = clubSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return clubList
    } catch (error) {
      console.error("Error fetching clubs or memberships:", error);
    } 
  };

  export const fetchClubs = async () => {
    try {
      const clubSnapshot = await getDocs(collection(db, CLUBS));
      
      const clubList = clubSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return clubList
    } catch (error) {
      console.error("Error fetching clubs or memberships:", error);
    } 
  };

export const fetchClubById = async (clubId) => {
    try {
      const clubDoc = doc(db, CLUBS, clubId);
      const clubSnapshot = await getDoc(clubDoc);
      if (clubSnapshot.exists()) {
        return clubSnapshot.data()
      }
      else {
      console.error("No such document!");
      return null
    }
    } catch (error) {
      console.error("Error fetching clubs or memberships:", error);
    } 
  };

  export const fetchMemberships = async (currentUserId) => {
    try {
      const membershipsSnapshot = await getDocs(query(collection(db, MEMBERSHIPS), where("user", "==", currentUserId)));
      return membershipsSnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error("Error fetching clubs or memberships:", error);
    } 
  };

  export const fetchEnrollments = async () => {
    try {
      const enrollmentsSnapshot = await getDocs(collection(db, ENROLLMENTS));
      const enrollments = enrollmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return enrollments
    } catch (error) {
      console.error("Error fetching enrollments", error);
    } 
  };

  export const fetchAllProjects = async () => {
    try {
        const clubSnapshot = await getDocs(collection(db, CLUB_PROJECTS));
        const clubProjectList = clubSnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))

        return clubProjectList
    } catch (error) {
        console.error("Error fetching clubs or memberships:", error);
    } finally {
        console.log(true);
    }
  };

  export const fetchUpdatesByClub = async (clubId) => {
    try {
      const docRef = doc(db, UPDATES, clubId);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        return { id: docSnapshot.id, ...docSnapshot.data() };
      } else {
        console.error("No document found for the provided clubId.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching updates by clubId:", error);
    }
  };

  export const checkMembership = async (currentUser,clubId) => {
    if (currentUser) {
      const membershipsCollection = collection(db, "memberships");
      const membershipsQuery = query(membershipsCollection, where("user", "==", currentUser.id), where("clubId", "==", clubId));
      const membershipsSnapshot = await getDocs(membershipsQuery);
      return membershipsSnapshot
    }
  };

  export const fetchSubmissionByUser = async (clubId, projectId, team, user) => {
    try {
      const submissionId = `${clubId}_${projectId}_${team}_${user}`
      const clubDoc = doc(db, SUBMISSIONS, submissionId);
      const clubSnapshot = await getDoc(clubDoc);
      if (clubSnapshot.exists()) {
        return clubSnapshot.data()
      }
      else {
      console.error("No such submission document!");
      return null
    }
    } catch (error) {
      console.error("Error fetching submission", error);
    } 
  };